import Logo from "../../assets/img/Logo.png"
import { NavLink } from "react-router-dom";
import { Disclosure } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { useOneTapProvider } from '../../utils/Globals/CustomHooks';
import { sphereoneSdk } from '../../utils/SphereOne';
import { useNavigate } from 'react-router-dom';

const NavBar = () => {

    const { loginMethod, isSphereOneConnected, isEthereumConnected, profileInformation, setLoginMethod, setIsSphereOneConnected, setIsEthereumConnected, } = useOneTapProvider();
    const navigate = useNavigate();

    const logoutFn = async () => {
        localStorage.removeItem('profileInformation');
        localStorage.removeItem('loginMethod');
        localStorage.removeItem('isEthereumConnected');
        localStorage.removeItem('isWalletConnected');
        localStorage.removeItem('filteredWallets');
        if (localStorage.getItem("WALLET_ADDRESS")) {
            localStorage.removeItem("STARK_PUBLIC_KEY");
            localStorage.removeItem("WALLET_ADDRESS");
        }
        if (isSphereOneConnected) { //Entering here means that I logged in using Sphere One, and will now logout from that
            try {
                sphereoneSdk.logout();
            } catch (e) {
                console.error(e);
            }
        } else if (isEthereumConnected) { // This is the same from up top, but with ethereum
            const providerMetamask = await (window as any).ethereum;
            if (providerMetamask) {
                try {
                    await providerMetamask.request({
                        "method": "wallet_revokePermissions",
                        "params": [
                            {
                                "eth_accounts": {}
                            }
                        ]
                    });
                } catch (error) {
                    console.error("Error revoking wallet permissions:", error);
                }
            }
            if (localStorage.getItem("WALLET_ADDRESS")) {
                localStorage.removeItem("STARK_PUBLIC_KEY");
                localStorage.removeItem("WALLET_ADDRESS");
            }
        }
        setLoginMethod('Null')
        setIsSphereOneConnected(false)
        setIsEthereumConnected(false)
        window.location.reload()
    }

    return (
        <header className='py-5'>
            <Disclosure as="nav" className='container text-white'>
                {({ open }) => (
                    <>
                        <div className='relative flex justify-between items-center'>
                            <div className="inset-y-0 left-0 flex items-center sm:hidden w-28">
                                {/* Mobile menu button*/}
                                <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                                    <span className="sr-only">Open main menu</span>
                                    {open ? (
                                        <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                                    ) : (
                                        <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                                    )}
                                </Disclosure.Button>
                            </div>
                            <div
                                style={{
                                    width: '250px',
                                    cursor: 'pointer',
                                }}
                                onMouseEnter={() => document.body.style.cursor = 'pointer'}
                                onMouseLeave={() => document.body.style.cursor = 'auto'}
                                onClick={() => { navigate("/") }}
                            >
                                <img
                                    src={Logo}
                                    alt="Logo"
                                    style={{
                                        width: '100%',
                                        height: 'auto',
                                        borderRadius: '8px',
                                    }}
                                />
                            </div>

                            <div className='menu'>

                                <div className='hidden sm:ml-6 sm:block'>
                                    <ul className='flex items-center justify-center px-5'>
                                        <li className='pr-8'>
                                            <NavLink to="/" className={({ isActive, isPending }) =>
                                                isPending ? "pending" : isActive ? "active text-lg font-medium" : "text-lg font-medium text-custom-gray"
                                            }>Home</NavLink>
                                        </li>
                                        <li className='pr-8' >
                                            <button onClick={(() => { window.open('https://playonetap.com/download', '_blank') })} className="button-style text-lg font-medium text-custom-gray">
                                                {"Play Now"}
                                            </button>
                                        </li>
                                        <li className='pr-8' >
                                            <button onClick={(() => { window.open('https://qorbiworld.com/leaderboard', '_blank') })} className="button-style text-lg font-medium text-custom-gray">
                                                {"Leaderboard"}
                                            </button>
                                        </li>
                                        <li className='pr-8' >
                                            <button onClick={(() => { window.open('https://more.playonetap.com/', '_blank') })} className="button-style text-lg font-medium text-custom-gray">
                                                {"More"}
                                            </button>
                                        </li>
                                        <li className='pr-8'>
                                            <NavLink to={'https://qorbiworld.com/profile'}
                                                className={"text-lg font-medium text-custom-gray"}>
                                                Sign In
                                            </NavLink>
                                        </li>
                                        {loginMethod !== "Null" ?
                                            <>
                                                <li className='pr-8' >
                                                    <button onClick={logoutFn} className="button-style">
                                                        {"Logout"}
                                                    </button>
                                                </li>
                                                {profileInformation && 'profilePicture' in profileInformation && (
                                                    <li className='pr-8'>
                                                        <div className='flex items-center'>
                                                            <img
                                                                src={profileInformation.profilePicture as string}
                                                                alt='Profile'
                                                                className='rounded-full h-8 w-8 object-cover'
                                                            />
                                                            {('name' in profileInformation || 'username' in profileInformation) && (
                                                                <span className='ml-2 text-lg font-medium text-custom-gray'>
                                                                    {(profileInformation as { name?: string; username?: string }).name
                                                                        ? (profileInformation as { name?: string; username?: string }).name
                                                                        : (profileInformation as { name?: string; username?: string }).username}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </li>
                                                )}
                                            </>
                                            :
                                            null
                                        }
                                    </ul>
                                </div>
                            </div>

                        </div>
                        <div className="sm:hidden mt-5">
                            {
                                open ?
                                    <div className="space-x-4">
                                        <ul className='text-left'>
                                            <li className='pb-5'>
                                                <NavLink to="/" className={({ isActive, isPending }) =>
                                                    isPending ? "pending" : isActive ? "active" : ""
                                                }>Home</NavLink>
                                            </li>
                                            <li className='pb-5' >
                                                <button onClick={(() => { window.open('https://playonetap.com/download', '_blank') })} className="pending">
                                                    {"Play Now"}
                                                </button>
                                            </li>
                                            <li className='pb-5' >
                                                <button onClick={(() => { window.open('https://qorbiworld.com/leaderboard', '_blank') })} className="pending">
                                                    {"Leaderboard"}
                                                </button>
                                            </li>
                                            <li className='pb-5' >
                                                <button onClick={(() => { window.open('https://more.playonetap.com/', '_blank') })} className="pending">
                                                    {"More"}
                                                </button>
                                            </li>
                                            <li className='pb-5'>
                                                <NavLink to={'https://qorbiworld.com/profile'}
                                                    className={"pending"}>
                                                    Sign In
                                                </NavLink>
                                            </li>
                                            {loginMethod !== "Null" ?
                                                <li className='pb-5' >
                                                    <button onClick={logoutFn} className="button-style">
                                                        {"Logout"}
                                                    </button>
                                                </li>
                                                :
                                                null
                                            }
                                        </ul>
                                    </div>
                                    :
                                    null
                            }
                        </div>
                    </>
                )}
            </Disclosure>
        </header>
    );
};

export default NavBar;
